import React from 'react';
import { IonCheckbox, IonItem, IonLabel, IonNote } from '@ionic/react';
import Basket from '../../lib/basket';
import { NormalText, Subtitle } from '../common';

const { formatPrice } = Basket;

export const SubscriptionLine = (__, clickHandler, key, isChecked, name, description, price) => (
  <IonItem class='subscription-item' lines="none" key={key}>
    <IonCheckbox  color="secondary" slot="start" checked={isChecked} onClick={clickHandler} />
    <div style={{flex:'1'}}>
      <Subtitle className='bold'>{name}</Subtitle>
      <NormalText>{description}</NormalText>
    </div>
    <div>
      <Subtitle className='bold'>{formatPrice(price)}</Subtitle>
      <NormalText>{__('per month')}</NormalText>
    </div>
  </IonItem>
);
