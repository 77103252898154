import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  IonButton,
  IonCheckbox,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonItem,
  IonIcon,
  IonList,
  IonInput,
} from '@ionic/react';
import moment from '../../lib/moment';

import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import Modal from '../../components/modal';
import { setDeliveryOption } from '../../store/actions';

import {
  Title,
  SmallText,
  Sectiontitle,
  Spacer,
  FlexSpacer,
  NormalText,
  StrongText,
  Subtitle,
} from '../../components/common';
import { isDefined, isEmptyObject, makeKey, deepCopy, forwardTo } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import Basket from '../../lib/basket';
import { setScrollTop } from '../../store/actions';
import { OrderSummary } from '../orderSummary';
import { ItemDetailsRaw } from '../itemDetails';
import OrderList from './orderList';
import { close, search } from 'ionicons/icons';
import '../order/index.css';
import './index.css';
import { getConfig } from '../../appConfig';

import mainLogo from '../../assets/images/logo-black.png';

const {
  validateItem,
  getProductName,
  getProductDescription,
  _calculateItemPrice,
  formatPrice,
  addToBasket,
  isChoicesGroupValid,
} = Basket;

const defaultModalItemOptions = {
  quantity: 1,
  price: 0,
  selectedChoices: [],
  instructions: '',
};

// category menu calc
const rowHeight = 35; // <-- within error of 1 or 2 pixels
const topOffset = 50;

class OrderPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCategory: 0,
      ikentooMenu: null,
      originalMenu: null,
      searchItemsValue: '',
      menuRefs: null,
      categoriesPositionTop: [],
      modalOpen: false,
      modalItem: null,
      modalItemOptions: defaultModalItemOptions,
      validationErrors: [],
      showValidationAlert: false,
      previousRowNum: 1,
      menuHero: '',
      title: '',
      description: '',
      mainLogo: '',
      nameVisible: true,
    };
    this.selectCategoryOnScroll = this.selectCategoryOnScroll.bind(this);
  }
  onContentScrollHandler() {
    const nameVisible = this.isScrolledIntoView(document.querySelector('.item-details-modal-name'));
    if (this.state.nameVisible != nameVisible) {
      if (!nameVisible) {
        this.setState({ nameVisible: false });
      } else {
        this.setState({ nameVisible: true });
      }
    }
  }
  isScrolledIntoView(el) {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top - 90;
    var elemBottom = rect.bottom;
    var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
    return isVisible;
  }
  setIkentooMenu = (menu) => {
    this.setState(
      {
        ikentooMenu:
          menu || (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu))
            ? this.props.defaultMenu
            : this.props.ikentooMenu,
        originalMenu:
          menu || (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu))
            ? this.props.defaultMenu
            : this.props.ikentooMenu,
      },
      () => {
        let menuRefs = this.menuRefs(this.state.ikentooMenu);
        this.setState({ menuRefs });
      },
    );
  };

  onWindowResize = () => {
    setTimeout(() => {
      const catMenu = document.querySelector('.order-categories-inner');
      if (catMenu) {
        const rowNum = Math.round(catMenu.clientHeight / rowHeight);
        if (rowNum !== this.state.previousRowNum) {
          this.setState({ previousRowNum: rowNum });
          const subHeaders = document.querySelectorAll('.order-sublist-header');
          subHeaders.forEach((item) => (item.style.top = topOffset + rowNum * rowHeight + 'px'));
        }
      }
    }, 0);
  };

  async componentDidMount() {
    const { clientProfile } = this.props;
    this.setIkentooMenu();

    this.setState({
      menuHero: '',
      title: '',
      description: '',
      mainLogo: '',
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.clientProfileUpdated !== nextProps.clientProfileUpdated) {
      this.setState({
        menuHero: nextProps.clientProfile.hero_image,
        title: nextProps.clientProfile.buisiness_name,
        description: nextProps.clientProfile.buisiness_description,
      });
    }
    const prevMenuName = (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)
      ? nextProps.defaultMenu
      : nextProps.ikentooMenu || {}
    ).menuName;
    const currentMenuName = (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)
      ? this.props.defaultMenu
      : this.props.ikentooMenu || {}
    ).menuName;
    if (
      prevMenuName !== currentMenuName ||
      nextProps.restaurantsUpdated !== this.props.restaurantsUpdated
    ) {
      this.setIkentooMenu(
        Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)
          ? nextProps.defaultMenu
          : nextProps.ikentooMenu,
      );
    }
    return true;
  }
  filterMenu(menu) {
    if (menu && menu.menuEntryGroups) {
      menu.menuEntryGroups = this.filterIkentooMenuItems(menu.menuEntryGroups);
      return menu;
    }
    return menu;
  };
  filterIkentooMenuItems(items) {
    let filteredItems = [];
    items.forEach((item) => {
      if (!item.menuEntry) {
        // break recursion when arrive to the product
        if (item.productName.toLowerCase().includes(`${this.state.searchItemsValue}`)) {
          filteredItems.push(item);
          return [item];
        } else {
          return [];
        }
      } else {
        const len = (item.menuEntry || []).length;
        if (len > 0) {
          const newFilteredItems = this.filterIkentooMenuItems(item.menuEntry);
          if (newFilteredItems.length > 0) {
            item.menuEntry = newFilteredItems;
            filteredItems.push(item);
          }
        }
      }
    });

    return filteredItems;
  };
  filterMenuItems = (e) => {
    this.setState({ searchItemsValue: e.target.value }, () => {
      const menu = JSON.parse(JSON.stringify(this.state.originalMenu))
      const filtered = this.filterMenu(menu)
      this.setState({ ikentooMenu: filtered }, () => {
        let menuRefs = this.menuRefs(this.state.ikentooMenu);
        this.setState({ menuRefs });
      })
    })
  }
  menuRefs = (menuRefs) => {
    return menuRefs && menuRefs.menuEntryGroups
      ? menuRefs.menuEntryGroups.reduce((acc, value, index) => {
        let name = value.name + '_' + index;
        acc[name] = React.createRef();
        return acc;
      }, {})
      : {};
  };

  scrollTo = (name) => {
    let orderListHeading = document.querySelector('.order-list-heading');
    let orderListHeadingHeight = 0;
    if (orderListHeading) {
      orderListHeadingHeight = orderListHeading.clientHeight;
    }
    const additionalHeight = document.querySelector('.order-list-restaurant-image') ? 50 : 0
    document.querySelector('.no-padding').scroll({
      top: this.state.menuRefs[name].current.offsetTop + orderListHeadingHeight + additionalHeight,
      behavior: 'smooth',
    });
  };
  filterMenu(menu) {
    if (menu && menu.menuEntryGroups) {
      menu.menuEntryGroups = this.filterIkentooMenuItems(menu.menuEntryGroups);
      return menu;
    }
    return menu;
  };
  filterIkentooMenuItems(items) {
    let filteredItems = [];
    items.forEach((item) => {
      if (!item.menuEntry) {
        // break recursion when arrive to the product
        if (item.productName.toLowerCase().includes(`${this.state.searchItemsValue.toLowerCase()}`)) {
          filteredItems.push(item);
          return [item];
        } else {
          return [];
        }
      } else {
        const len = (item.menuEntry || []).length;
        if (len > 0) {
          const newFilteredItems = this.filterIkentooMenuItems(item.menuEntry);
          if (newFilteredItems.length > 0) {
            item.menuEntry = newFilteredItems;
            filteredItems.push(item);
          }
        }
      }
    });

    return filteredItems;
  };
  filterMenuItems = (e) => {
    this.setState({ searchItemsValue: e.target.value }, () => {
      const menu = JSON.parse(JSON.stringify(this.state.originalMenu))
      const filtered = this.filterMenu(menu)
      this.setState({ ikentooMenu: filtered }, () => {
        let menuRefs = this.menuRefs(this.state.ikentooMenu);
        this.setState({ menuRefs });
      })
    })
  }
  selectCategoryOnScroll = (e) => {
    e.preventDefault();
    const headingInfo = document.querySelector('.order-list-restaurant-image');
    const positionTop =
      headingInfo && e.target.scrollTop - headingInfo.clientHeight > 0
        ? e.target.scrollTop - headingInfo.clientHeight
        : e.target.scrollTop;
    const { menuRefs } = this.state;
    setTimeout(() => {
      const positions = Object.keys(menuRefs).map((key) => {
        if (!menuRefs[key].current) {
          return null;
        }
        return menuRefs[key].current.offsetTop;
      });
      let selectCategory = 0;
      positions.forEach((item, i) => {
        if (item <= positionTop) {
          selectCategory = i;
        }
      });
      this.props.dispatch(setScrollTop(positionTop));
      this.setState({ selectedCategory: selectCategory });
    }, 150);
  };

  showModal = (modalItem) => {
    this.setState(
      {
        modalItem,
        modalItemOptions: {
          ...this.state.modalItemOptions,
          price: _calculateItemPrice({ item: modalItem, quantity: 1 }),
          selectedChoices:
            modalItem.menuDealGroups && modalItem.menuDealGroups.length
              ? Array(modalItem.menuDealGroups.length).fill([])
              : [],
          validationErrors:
            modalItem.menuDealGroups && modalItem.menuDealGroups.length
              ? Array(modalItem.menuDealGroups.length).fill(null)
              : [],
        },
        modalOpen: true,
      },
      () => {
        setTimeout(() => {
          const splitPane = document.querySelector('ion-split-pane');
          if (splitPane) {
            splitPane.classList.add('blur');
          }
        }, 300);
      },
    );
  };

  handleInputChange = (groupIndex, choiceIndex, multiSelectionPermitted, event) => {
    const item = this.state.modalItem;
    const { selectedChoices, quantity } = this.state.modalItemOptions;
    const allGroups = item.menuDealGroups ? item.menuDealGroups : [];
    const selectedGroup = allGroups[groupIndex];
    if (selectedGroup) {
      const selectedChoice = selectedGroup.items[choiceIndex];
      let updatedSelectedChoices = deepCopy(selectedChoices);
      if (multiSelectionPermitted) {
        //checkbox
        if (event.target.checked) {
          updatedSelectedChoices[groupIndex].push(selectedChoice);
        } else {
          updatedSelectedChoices[groupIndex] = updatedSelectedChoices[groupIndex].filter(
            (i) => i.sku !== selectedChoice.sku,
          );
        }
      } else {
        //radio
        if (!selectedChoice) {
          updatedSelectedChoices[groupIndex] = [];
        } else {
          updatedSelectedChoices[groupIndex] = [selectedChoice];
        }
      }

      this.setState(
        {
          modalItemOptions: {
            ...this.state.modalItemOptions,
            selectedChoices: updatedSelectedChoices,
          },
        },
        () => {
          const { selectedChoices } = this.state.modalItemOptions;
          //recalculate item price on every menu deal choice change
          this.setState({ price: _calculateItemPrice({ item, quantity, selectedChoices }) }, () => {
            const validationErrors = validateItem(this.constructBasketItem());
            this.setState({ validationErrors: validationErrors.errors });
          });
        },
      );
    }
  };

  drawGroupChoices = (choices = [], multiSelectionPermitted, groupIndex) => {
    const { selectedChoices } = this.state.modalItemOptions;
    const { profile, __ } = this.props;
    const allChoices = choices.map((item, choiceIndex) => {
      const { sku, productPrice } = item;
      const isChecked = !!(selectedChoices[groupIndex] || []).find((i) => i.sku === sku);
      return (
        <IonItem lines="none" key={makeKey(choiceIndex, sku, groupIndex)}>
          <div tabIndex="-1"></div>
          {multiSelectionPermitted ? (
            <IonCheckbox
              color="primary"
              slot="start"
              checked={isChecked}
              onIonChange={(event) => {
                this.handleInputChange(groupIndex, choiceIndex, multiSelectionPermitted, event);
              }}
            />
          ) : (
            <IonRadio
              slot="start"
              className="details-radio"
              color="primary"
              value={choiceIndex}
              checked={isChecked}
            />
          )}
          <IonLabel className="ion-text-wrap">
            <Sectiontitle className="single-item">{__(getProductName(item, profile))}</Sectiontitle>
            {getProductDescription(item, profile) ? (
              <SmallText className="no-margin">{getProductDescription(item, profile)}</SmallText>
            ) : null}
          </IonLabel>
          <p>{formatPrice(productPrice)}</p>
        </IonItem>
      );
    });
    if (multiSelectionPermitted) {
      return allChoices;
    } else {
      //radio
      return (
        <IonRadioGroup
          onIonChange={(event) => {
            this.handleInputChange(groupIndex, event.target.value, multiSelectionPermitted, event);
          }}
        >
          {allChoices}
        </IonRadioGroup>
      );
    }
  };

  drawGroupLabel = (label, groupIndex) => {
    const { validationErrors } = this.state;
    const { __ } = this.props;

    return (
      <>
        <IonItem>
          <div className="sectiontitle">{label}</div>
        </IonItem>
        {validationErrors[groupIndex] ? (
          <div className="field-error">{__(validationErrors[groupIndex])}</div>
        ) : null}
      </>
    );
  };

  drawMenuDealGroups = (menuGroupItem, index) => {
    // multiSelectionPermitted = true  --> only one item must be selected
    const multiSelectionPermitted = menuGroupItem.multiSelectionPermitted;
    if (isChoicesGroupValid(menuGroupItem)) {
      if (isDefined(multiSelectionPermitted)) {
        return (
          <div key={index}>
            {this.drawGroupLabel(menuGroupItem.description, index)}
            {this.drawGroupChoices(menuGroupItem.items, multiSelectionPermitted, index)}
          </div>
        );
      } else {
        return this.drawGroupLabel(menuGroupItem.description);
      }
    }
  };

  constructBasketItem = () => {
    const { modalItem, modalItemOptions } = this.state;
    const { quantity, selectedChoices, instructions } = modalItemOptions;
    let constructModel = {};
    if (modalItem) {
      constructModel = {
        item: modalItem,
        quantity,
        selectedChoices,
        instructions,
      };
    }
    return constructModel;
  };

  onIncrementerUpdate = (newQuantity) => {
    const price = _calculateItemPrice({
      item: this.state.modalItem,
      quantity: newQuantity,
      selectedChoices: this.state.modalItemOptions.selectedChoices,
    });
    this.setState({
      modalItemOptions: { ...this.state.modalItemOptions, quantity: newQuantity, price },
    });
  };

  addToOrder = () => {
    const newBasketItem = this.constructBasketItem();
    const validationErrors = validateItem(newBasketItem);
    if (validationErrors.errorCount > 0) {
      this.setState({ validationErrors: validationErrors.errors });
      this.setState({ validationErrors: validationErrors.errors }, () => {
        this.setShowValidationAlert(true);
      });
    } else {
      addToBasket(newBasketItem);
      this.setState({
        modalItem: null,
        modalItemOptions: defaultModalItemOptions,
        modalOpen: false,
      });
      /*forwardTo('/order-summary')
      forwardTo('/order-summary', { fromItemDetails: true })*/
    }
  };
  isStoreOpened = () => {
    const { restaurants } = this.props;
    const filteredRestaurants = restaurants.filter((restaurant) => restaurant.name !== 'Vouchers');
    const selectedRestaurant = filteredRestaurants[0];
    let currentDay = new Date().getDay();
    if (currentDay == 0) {
      currentDay = 7;
    }
    let label = '';

    const openingTimes = selectedRestaurant.json_opening_time_info[currentDay - 1]?.time;
    if (openingTimes && openingTimes.toLowerCase() !== 'closed') {
      const openingTimesForToday = openingTimes.split('-').map((time) => {
        return {
          hour: time.split(':')[0].trim(),
          minutes: time.split(':')[1].trim(),
        };
      });
      const startTime = openingTimesForToday[0];
      const closeTime = openingTimesForToday[1];
      const current = moment().format('HH-mm').split('-');
      const currentTime = { hour: current[0], minutes: current[1] };
      if (currentTime.hour > closeTime.hour || currentTime.hour < startTime.hour) {
        label = 'CLOSED';
      } else if (currentTime.hour < closeTime.hour && currentTime.hour > startTime.hour) {
        label = 'OPEN';
      } else if (currentTime.hour === closeTime.hour) {
        if (currentTime.minutes == closeTime.minutes) {
          label = 'OPEN';
        } else if (currentTime.minutes > closeTime.minutes) {
          label = 'OPEN';
        } else {
          label = 'CLOSED';
        }
      } else if (currentTime.hour === startTime.hour) {
        if (currentTime.minutes == startTime.minutes) {
          label = 'OPEN';
        } else if (currentTime.minutes > startTime.minutes) {
          label = 'OPEN';
        } else {
          label = 'CLOSED';
        }
      }
    } else {
      label = 'CLOSED';
    }
    return label;
  };
  getClass = (id) => {
    let backgroundImage;
    switch (id) {
      case 'delivery':
        backgroundImage = 'delivery-Icon';
        break;
      case 'charter-delivery':
        backgroundImage = 'delivery-icon';
        break;
      case 'table':
        backgroundImage = 'table-icon';
        break;
      default:
        backgroundImage = 'collection-icon';
    }
    return backgroundImage;
  };
  instructionsChange = (event) => this.setState({ instructions: event.target.value });

  closeModal = () =>
    this.setState(
      {
        modalOpen: false,
        modalItemOptions: defaultModalItemOptions,
        validationErrors: [],
        nameVisible: true,
      },
      () => {
        const splitPane = document.querySelector('ion-split-pane');
        if (splitPane) {
          splitPane.classList.remove('blur');
        }
      },
    );
  setDeliveryOption = (delivery) => {
    this.props.dispatch(setDeliveryOption(delivery));
    Basket.setDeliveryOption(delivery);
    Basket.setOrderType(delivery.id);
    forwardTo(
      delivery.id === 'delivery'
        ? '/delivery'
        : delivery.id === 'charter-delivery'
          ? '/delivery'
          : delivery.id === 'pick-up-point'
            ? '/pick-up-point'
            : delivery.id === 'table'
              ? '/order-to-table'
              : '/click-and-collect',
      { isLogoClicked: false },
    );
  };

  render() {
    const { __, profile, history, restaurants } = this.props;
    const {
      selectedCategory,
      ikentooMenu,
      modalOpen,
      modalItem,
      menuHero,
      title,
      description,
    } = this.state;
    let categories = !isEmptyObject(ikentooMenu) ? ikentooMenu.menuEntryGroups : [];
    let modalItemImage =
      modalItem && modalItem.itemRichData && modalItem.itemRichData.squareImageUrl
        ? modalItem.itemRichData.squareImageUrl
        : '';
    const isLogoClicked = history?.location?.state?.isLogoClicked;
    if (modalItemImage && modalItemImage.indexOf('http://') !== -1) {
      modalItemImage = modalItemImage.replace(/http:\/\//g, 'https://');
    }
    return (
      <Loading>
        <Layout
          noPadding
          scrollY={false}
          color="transparent"
          selectCategoryOnScroll={this.selectCategoryOnScroll}
          hideSecondToolbar={true}
        >
          {!isDefined(ikentooMenu) && !isEmptyObject(ikentooMenu) && !ikentooMenu ? null : (
            <div className={'order-list' + (Basket.items.length < 1 ? ' order-list-full' : '')}>
              {ikentooMenu &&
                ikentooMenu.menuName &&
                ikentooMenu.menuName.indexOf('Default') === -1 &&
                !isLogoClicked ? null : (
                <div className="web-only order-list-heading">
                  <div className="order-list-restaurant-image">
                    <div
                      className="order-list-restaurant-bg"
                    ></div>
                  </div>
                  <div className="restaurant-info-banner">
                    <img className="client-logo" src={mainLogo} />
                    <div style={{ flex: 1 }}>
                      <Subtitle className="block">
                        {__(getConfig().general.description)}
                      </Subtitle>
                    </div>
                    <div className="restaurant-info-delivery-options">
                      <div>
                        <Subtitle className="bold block">{__(this.isStoreOpened())}</Subtitle>
                        <Subtitle>{restaurants[0].name}</Subtitle>
                      </div>
                      <div className="icons-wrapper">
                        {getConfig().delivery.map((d, index) =>
                          d.isRemoved ? null : (
                            <div
                              key={index}
                              className={this.getClass(d.id)}
                              onClick={() => this.setDeliveryOption(d)}
                            ></div>
                          ),
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="order-categories">
                <div className='search-box-wrapper'>
                  <Title style={{ margin: 0 }}>{__('Select Items')}</Title>
                  <div className='search-box'>
                    <IonIcon color='secondary' icon={search}></IonIcon>
                    <IonInput placeholder={__('Search products')} value={this.state.searchItemsValue} onIonChange={this.filterMenuItems} />
                    <IonIcon onClick={() => this.setState({ searchItemsValue: '' })} className='reset-icon' color='secondary' icon={close}></IonIcon>


                  </div>
                </div>
                {categories.length > 0 ?

                  <div className="order-categories-inner">
                    {categories.map((category, index) => {
                      return (
                        <IonButton
                          key={index}
                          size="small"
                          fill="clear"
                          className={
                            selectedCategory === index ? 'category-button active' : 'category-button'
                          }
                          onClick={() => this.scrollTo(`${category.name}_${index}`, index)}
                        >
                          {__(category.name)}
                        </IonButton>
                      );
                    })}
                  </div>
                  : <NormalText className='no-items-message'>{__('No items found')}</NormalText>}
                  </div>
              <div className="order-content">
                <OrderList
                  scrollTopPosition={this.props.scrollTop}
                  selectCategoryOnScroll={this.selectCategoryOnScroll}
                  showModal={this.showModal}
                  category={!isEmptyObject(ikentooMenu) ? ikentooMenu : null}
                  menuRefs={this.state.menuRefs ? this.state.menuRefs : []}
                />
              </div>
            </div>
          )}
          {Basket.items.length > 0 ? (
            <div className="order-summary-sidebar">
              <OrderSummary />
            </div>
          ) : null}
        </Layout>
        {modalOpen && (
          <Modal
            className="item-details-modal"
            isOpen={modalOpen}
            onDidDismiss={() => this.closeModal()}
          >
            {modalItem ? (
              <>
                <div className="item-details-card">
                  <div
                    onScroll={() => this.onContentScrollHandler()}
                    className={'item-details-content'}
                    style={
                      getConfig()?.delivery?.filter((d) => !d.isRemoved && !d.isDisabled).length == 0
                        ? { height: '100%' }
                        : {}
                    }
                  >
                    <div
                      className={
                        this.state.nameVisible
                          ? 'item-details-model-header'
                          : 'item-details-model-header active'
                      }
                    >
                      <NormalText>
                        <strong>{__(getProductName(modalItem, profile))}</strong>
                      </NormalText>
                    </div>
                    <div className="item-details-modal-info">
                      {modalItemImage && modalItemImage !== '' ? (
                        <img src={modalItemImage} />
                      ) : (
                        <div className="image-placeholder"></div>
                      )}
                      <div>
                        <Title className="okx-font-secondary item-details-modal-name">
                          {__(getProductName(modalItem, profile))}
                        </Title>
                        {/* <div className="item-details-card-price okx-font-secondary">
                        {formatPrice(modalItem.productPrice)}
                      </div> */}
                      </div>
                    </div>
                    <ItemDetailsRaw
                      item={modalItem}
                      profile={profile}
                      modalOpen={modalOpen}
                      closeModal={this.closeModal}
                    />
                  </div>
                </div>
              </>
            ) : null}
          </Modal>
        )}
        {/* <IonAlert
					isOpen={ showValidationAlert }
					onDidDismiss={() => this.setState({ showValidationAlert: false })}
					header={ __('Validation') }
					message={ __('Please check any required options') }
					buttons={[{ text: 'Cancel', role: 'cancel', cssClass: 'secondary' }]}
				/> */}
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth, clientProfile, clientProfileUpdated } = state.common;
  const { restaurants, ikentooMenu, defaultMenu, restaurantsUpdated } = state.restaurants;
  const { scrollTop } = state.orders;
  return {
    auth,
    restaurants: restaurants || [],
    ikentooMenu: ikentooMenu || {},
    defaultMenu: defaultMenu || {},
    basketUpdated: state.orders.basketUpdated,
    scrollTop,
    storedItemWeb: state.orders.storedItemWeb,
    restaurantsUpdated,
    clientProfile,
    clientProfileUpdated,
  };
};

export default connect(stateToProps)(withTranslation(OrderPage));
