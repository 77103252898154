import React, { Component } from 'react';
import { withTranslation } from '../../lib/translate.js';
import noDataImg from '../../assets/images/logo-gray.png';
import './index.css';

class NoDataSecond extends Component {
  render() {
    const { __, label, className, style } = this.props;
    return (
      <div className={className || ''} style={{ textAlign: 'center', ...(style || {}) }}>
        <img style={{opacity:'0.2'}} src={noDataImg} className="no-data-img" />
        <div>{__(label || 'No data')}</div>
      </div>
    );
  }
}

export default withTranslation(NoDataSecond);
