import { Plugins, Capacitor } from '@capacitor/core';
import appConfig from '../appConfig';
import { loadStripe } from '@stripe/stripe-js';
import Basket from './basket';
import { isEmptyObject } from './utils';
import { getConfig } from '../appConfig';

const isWeb = () => Capacitor.platform === 'web';

// docs: https://github.com/stripe/react-stripe-elements
class Stripe {
  constructor() {
    this.stripe = null;
    this.success_message = 'Payment created successfully';
    this.failed_message = 'Payment status - failed';
    this.merchantName = 'Brunswick Hotel';
  }

  getStripeInstance = () => this.stripe;

  setStripePublishableKey = (token) => {
    if (token) {
      if (isWeb()) {
        return loadStripe(token).then((stripeInstance) => {
          this.stripe = stripeInstance;
          return;
        });
      } else {
        this.stripe = Plugins.Stripe;
        return this.stripe.setPublishableKey({ key: token || appConfig.services.stripe_key });
      }
    }
  };

  confirmPaymentIntent = (clientSecret, options = {}) => {
    if (isWeb()) {
      return this.stripe
        .confirmCardPayment(clientSecret, { payment_method: options.paymentMethodId })
        .then((res) => {
          return this.checkIntentResult(res);
        });
    } else {
      // this is used to confirm regular card and google pay payment intent
      return this.stripe.confirmPaymentIntent({ clientSecret, ...options }).then((res) => {
        if (isEmptyObject(res)) {
          res.status = 'succeeded';
        }
        return this.checkIntentResult(res);
      });
    }
  };

  checkIntentResult = (intent) => {
    const intentResult = intent.paymentIntent || intent;
    const retObj = {
      intentResult,
      isValid: false,
      message: this.failed_message,
    };

    if (intentResult && intentResult.status && intentResult.status === 'succeeded') {
      retObj.message = this.success_message;
      retObj.isValid = true;
    } else {
      if (intentResult.error && intentResult.error.message) {
        retObj.message += ' ' + intentResult.error.message;
      }
    }
    return retObj;
  };

  payWithGooglePay = (clientSecret, total = 0) => {
    if (!isWeb()) {
      return this.stripe
        .confirmPaymentIntent({
          clientSecret: clientSecret,
          googlePayOptions: {
            merchantName: this.merchantName,
            currencyCode: Basket.getSelectedCurrency().toUpperCase(),
            totalPrice: total,
            totalPriceStatus: 'FINAL',
            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
            allowedCardNetworks: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'],
          },
        })
        .then((res) => {
          return res;
        })
        .catch((e) => {
          throw e;
        });
    } else {
      return null;
    }
  };

  // this is used only to confirm apple pay payment intent
  // IMPORTANT:
  // 		Stripe.confirmPaymentIntent will trigger credit card picket on apple phone,
  // 		also
  // 		Stripe.payWithApplePay will trigger credit card picket on apple phone.
  // 		So only ONE call must be present.
  payWithApplePay = (clientSecret, profile, clientName) => {
    if (!isWeb()) {
      return this.stripe.isApplePayAvailable().then((result) => {
        if (result.available) {
          return this.stripe
            .confirmPaymentIntent({
              clientSecret,
              applePayOptions: {
                merchantId: getConfig().services.merchantIdentifier, // apple merchantIdentifier
                items: Basket.getItemsForApplePay(profile, this.merchantName),
                currency: Basket.getSelectedCurrency().toUpperCase(), // currency code (iso3)
                country: Basket.getCountry().toUpperCase(), // 2 letter country code (iso2)
              },
            })
            .then((res) => {
              if (!res.success) {
                throw new Error('Apple Payment Failed');
              }
              return { ...res, isValid: true };
            })
            .catch((e) => {
              throw e;
            });
        } else {
          throw new Error('Apple pay not available');
        }
      });
    } else {
      return null;
    }
  };
}

export default new Stripe();
