import { getEnv } from './lib/env.js';

let appConfig = {
  theme: {
    nav: {
      label: 'Brunswick Hotel',
    },
    showHeaderOnAuthRoutes: true,
    showInputIconsOnLogin: false,
    routesWithoutStaticHeader: ['/item-details'],
  },
  services: {
    google_maps: {
      google_api_key: getEnv('GOOGLE_API_KEY'),
    },
    // stripe_key: 'pk_test_SfLlfP41LhHVIG2vrA95ZrFt' //Kole
  },
  api: {
    // baseURL: "https://curry-leaf-api.5loyalty.com",
    baseURL: getEnv('BASE_URL'),
    // baseURL: 'https://hybrid-master.herokuapp.com',
    // baseURL: 'http://192.168.0.26:8000', //preda
    wordPressBaseURL: 'https://hybridapp.co.uk',
  },
  general: {
    dashboardText:
      'Take a taste adventure with our loyalty app and unlock a world of exclusive privileges with the club',
    clientName: 'Brunswick Hotel',
    appExitRoutes: ['/', '/home', '/dashboard'],
    authRoutes: ['/login', '/register', '/reset-password'],
    routesWithDarkBg: ['/dashboard', '/membership', '/my-membership'],
    routesWithBackButton: [
      '/cards',
      '/card-add',
      '/allergen-information',
      '/order-summary',
      '/order-completed',
      '/apply-vouchers',
      '/terms',
      '/privacy',
      '/contact-details',
      '/click-and-collect',
      '/order-to-table',
      '/history-details',
      '/delivery',
      '/my-membership',
      '/membership-terms',
      '/allergens',
      '/apply-loyalty',
      '/order',
      '/create-account',
      '/checkout',
      '/gift-vouchers',
      '/membership',
      '/checkout-membership'
    ],

    isReduxDevToolsOn: true,
    isWebPlatform: false,
  },
  // delivery: [
  // 	{
  // 		id: 'collection',
  // 		label: 'Click & Collect',
  // 		route: '/click-and-collect'
  // 	},
  // 	{
  // 		id: 'table',
  // 		label: 'Order To Table',
  // 		route: '/order-to-table'
  // 	},
  // ],
  flags: {
    specialInstructions: {
      isDisplayed: true,
    },
  },
  appType: {
    hasOrdering: true,
    hasLoyalty: true,
    hasEmailValidationEnabled: true,
    hasCampaignManager: true,
    referAFriend: true,
    hasCancelOrder: false,
    hasUnlockRewardsOnLoyalty: false,
    isReward: false,
    oldLayoutConfirmationScreens: false,
    hasCollectedPay: false,
  },
  configS3: {
    imageNamePrefix: 'hyfive_profile_image_',
    bucketName: 'hybrid-apps-profile-images',
    region: 'eu-west-1',
    accessKeyId: getEnv('S3_ACCESS_KEY_ID'),
    secretAccessKey: getEnv('S3_SECRET_ACCESS_KEY'),
  },

  firebaseConfig: {
    apiKey: getEnv('FIREBASE_API_KEY'),
    authDomain: getEnv('FIREBASE_AUTH_DOMAIN'),
    databaseURL: getEnv('FIREBASE_DATABASE_URL'),
    projectId: getEnv('FIREBASE_PROJECT_ID'),
    storageBucket: getEnv('FIREBASE_STORAGE_BUCKET'),
    messagingSenderId: getEnv('FIREBASE_MESSAGING_SENDER_ID'),
    appId: getEnv('FIREBASE_APP_ID'),
  },
  payment: 'stripe',
  localization: {
    defaultLocale: 'en',
  },
  sidebarLinks: [
    {
      title: 'Horizon Restaurant',
      items: [
        {
          label: 'View our menu',
          link: 'https://horizons-restaurant.paperlessmenu.com/menu/',
          icon:
            `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-book" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 19a9 9 0 0 1 9 0a9 9 0 0 1 9 0" /><path d="M3 6a9 9 0 0 1 9 0a9 9 0 0 1 9 0" /><line x1="3" y1="6" x2="3" y2="19" /><line x1="12" y1="6" x2="12" y2="19" /><line x1="21" y1="6" x2="21" y2="19" /></svg>`,
        },
        {
          label: 'Book a table',
          link: 'https://go-to-dishcult.app.link/pCGYannj9nb',
          icon:
            `<svg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-list-check' width='44' height='44' viewBox='0 0 24 24' stroke-width='1.5' fill='none' stroke-linecap='round' stroke-linejoin='round'> <path stroke='none' d='M0 0h24v24H0z' fill='none'/> <path d='M3.5 5.5l1.5 1.5l2.5 -2.5'/> <path d='M3.5 11.5l1.5 1.5l2.5 -2.5'/> <path d='M3.5 17.5l1.5 1.5l2.5 -2.5'/> <line x1='11' y1='6' x2='20' y2='6'/> <line x1='11' y1='12' x2='20' y2='12'/> <line x1='11' y1='18' x2='20' y2='18'/></svg>`,
        },
      ],
    },
  ],
  menuDisplayType: 3,
};

export const updateConfig = (newConfig) => (appConfig = newConfig);

export const getConfig = () => appConfig;

export default appConfig;
