import React from 'react';
import {
  IonButton,
  IonInput,
  IonToggle,
  IonItem,
  IonLabel,
  IonList,
  IonAlert,
  IonCheckbox,
} from '@ionic/react';
import { connect } from 'react-redux';
// import { getConfig } from '../../appConfig'
import Layout from '../../components/layout';
import PasswordInput from '../../components/passwordInput';
import { forwardTo, getDefaultRoute, goBack } from '../../lib/utils';
import {
  registerRequest,
  setModal,
  setSysLocale,
  setRegisterForm,
  updateProfile,
} from '../../store/actions';
import { withTranslation } from '../../lib/translate';
import { beforeShowTimePicker, beforeCloseTimePicker } from '../../store/actions';
import {
  FieldError,
  Title,
  SmallText,
  Spacer,
  NormalText,
  StrongText,
} from '../../components/common';
import { validateForm } from '../../lib/utils';
import Loading from '../../components/spinner';
import { Plugins } from '@capacitor/core';
import './index.css';
import Mobiscroll from '../../components/mobiscroll';
import logoGray from '../../assets/images/logo-gray.png';
import moment from 'moment';

const { Device } = Plugins;
const { DatePicker } = Mobiscroll;

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {},
      showAdditionalDetails: false,
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.navToTerms = this.navToTerms.bind(this);
    this.formConfig = {
      email: { type: 'email', required: true },
      password: { type: 'password', required: true },
      first_name: { type: 'text', required: false },
      last_name: { type: 'text', required: false },
      mobile: { type: 'tel', required: false },
      birthday: { type: 'text', required: false },
      accepted_terms_and_conditions: { type: 'toggle', toggle: true },
    };
  }

  handleInput(key, val) {
    const { dispatch } = this.props;
    dispatch(setRegisterForm(key, val));
    if (key === 'accepted_terms_and_conditions') {
      let formErrors = { ...this.state.formErrors };
      formErrors.accepted_terms_and_conditions = undefined;
      this.setState({ formErrors });
    }
  }

  validateForm() {
    const { registerFormData } = this.props;
    let formErrors = validateForm(this.formConfig, registerFormData);
    this.setState({ formErrors });
    return Object.keys(formErrors).length === 0;
  }

  handleRegister() {
    const { protectedReferrer } = this.props;
    this.props.dispatch(registerRequest({ referrer: '/register' }));
  }
  componentDidMount() {
    const { loggedIn } = this.props.auth;
    Device.getLanguageCode().then((res) => {
      const sysLocale = res.value.substr(0, 2);
      if (sysLocale) {
        this.props.dispatch(setSysLocale(sysLocale));
      }
    });
    if (loggedIn) {
      const defaultRoute = getDefaultRoute();
      forwardTo(defaultRoute.path);
    }
  }

  returnToLogin = (history) => history.goBack();

  navToTerms = () => forwardTo('/terms');
  backHandler = () => {
    if (!this.state.showTime) {
      goBack();
    } else {
      this.setState({ showTime: false });
    }
  };
  render() {
    const { __, isRegisterModalOpen, registerFormData } = this.props;
    const email = registerFormData.email;
    const password = registerFormData.password;
    const first_name = registerFormData.first_name;
    const last_name = registerFormData.last_name;
    const mobile = registerFormData.mobile;
    const birthday = registerFormData.birthday;
    const served_by = registerFormData.served_by;
    const accepted_terms_and_conditions = registerFormData.accepted_terms_and_conditions;
    const is_subscribed = registerFormData.is_subscribed;
    const { loggedIn } = this.props.auth;
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    const defaultDateValue = moment().subtract(18, 'years');
    return (
      <Loading transparent>
        <Layout
          hideSecondToolbar={true}
          hideBack={true}
          headerTitle={__('Register')}
          color="transparent"
          contentClassName="register-background"
          backHandler={this.backHandler}
        >
          {!loggedIn ? (
            <div className="absolute-content flex-row-wrapper">
              <Title className="uppercase">{__('Create Account')}</Title>
              <NormalText>
                {__(
                  'Create an account to earn & redeem loyalty, access exclusive vouchers and offers and other app-only perks.',
                )}
              </NormalText>
              <Spacer size={1} />
              <IonList>
                <IonItem lines="none" className="input-field-wrapper">
                  <IonInput
                    placeholder={__('Email Address')}
                    onIonChange={(e) => {
                      this.handleInput('email', e.target.value);
                    }}
                    onIonBlur={(e) => this.handleInput('email', e.target.value)}
                    clearInput
                    required={true}
                    type="email"
                    pattern="email"
                    inputmode="email"
                    value={email}
                    autocomplete="on"
                  ></IonInput>
                </IonItem>
                <FieldError className="field-error" value={__(this.state.formErrors.email)} />
                <IonItem lines="none" className="input-field-wrapper">
                  <PasswordInput
                    placeholder={__('Password')}
                    onIonChange={(e) => this.handleInput('password', e.target.value)}
                    value={password}
                  />
                </IonItem>
                <FieldError className="field-error" value={__(this.state.formErrors.password)} />
              </IonList>
              <Spacer />
              <div className="box-holder box-holder--register">
                <IonList>
                  <div lines="none">
                    <div tabIndex="-1"></div>
                    <div className="toggle">
                      <IonLabel>
                        <StrongText
                          style={{ display: 'block', marginBottom: '10px' }}
                          className="roboto-slab"
                        >
                          {__('Terms & Conditions')}
                        </StrongText>
                        <div className="register-terms-wrapper">
                          <IonCheckbox
                            color="secondary"
                            checked={accepted_terms_and_conditions}
                            onIonChange={(e) =>
                              this.handleInput('accepted_terms_and_conditions', e.detail.checked)
                            }
                          />
                          <IonLabel className="ion-text-wrap">
                            <NormalText color="primary">
                              {__('By signing up you agree to') + ' '}{' '}
                              <span
                                className="pointer underlined secondary-color"
                                onClick={() => forwardTo('/terms')}
                              >
                                {__('our terms and conditions')}
                              </span>{' '}
                              {__('and')}{' '}
                              <span
                                className="secondary-color  pointer underlined"
                                onClick={() => forwardTo('/privacy')}
                              >
                                {__('privacy policy')}
                              </span>
                            </NormalText>
                          </IonLabel>
                        </div>
                      </IonLabel>
                      <FieldError
                        className="field-error"
                        value={__(this.state.formErrors.accepted_terms_and_conditions)}
                      />
                    </div>
                  </div>
                  <div lines="none">
                    <div tabIndex="-1"></div>
                    <div className="toggle">
                      <IonLabel>
                        <div className="register-terms-wrapper">
                          <IonCheckbox
                            color="secondary"
                            checked={is_subscribed}
                            onIonChange={(e) => this.handleInput('is_subscribed', e.detail.checked)}
                          />
                          <IonLabel className="ion-text-wrap">
                            <NormalText color="primary">
                              {__(
                                "I'd like to receive email updates that contain news, offers and promotions",
                              )}
                            </NormalText>
                          </IonLabel>
                        </div>
                      </IonLabel>
                    </div>
                  </div>
                </IonList>
                <Spacer size={1} />
                <IonButton
                  expand="block"
                  color="secondary"
                  onClick={() => {
                    if (this.validateForm()) this.handleRegister();
                  }}
                >
                  {__('Continue')}
                </IonButton>
                <Spacer size={2} />
                <img className="centered block additional-image" src={logoGray} />
              </div>
            </div>
          ) : (
            <div className="absolute-content flex-row-wrapper">
              <Title className="uppercase">{__('About You')}</Title>
              <NormalText>{__('Tell us some more details (optional)')}</NormalText>
              <Spacer size={2} />
              <IonList>
                <IonItem lines="none" className="input-field-wrapper">
                  <IonInput
                    placeholder={__('First name')}
                    onIonChange={(e) => this.handleInput('first_name', e.target.value)}
                    required={true}
                    type="text"
                    pattern="text"
                    inputmode="text"
                    value={first_name}
                  ></IonInput>
                </IonItem>
                <Spacer size={1} />

                <IonItem lines="none" className="input-field-wrapper">
                  <IonInput
                    placeholder={__('Last name')}
                    onIonChange={(e) => this.handleInput('last_name', e.target.value)}
                    required={true}
                    type="text"
                    pattern="text"
                    inputmode="text"
                    value={last_name}
                  ></IonInput>
                </IonItem>
                <Spacer size={1} />
                <IonItem lines="none" className="input-field-wrapper">
                  <IonInput
                    placeholder={__('Mobile number')}
                    onIonChange={(e) => this.handleInput('mobile', e.target.value)}
                    required={true}
                    type="tel"
                    pattern="tel"
                    inputmode="tel"
                    value={mobile}
                  ></IonInput>
                </IonItem>
                <Spacer size={1} />

                <IonItem lines="none" className="input-field-wrapper">
                  <DatePicker
                    className="data-picker-input"
                    display="bottom"
                    defaultValue={defaultDateValue}
                    max={yesterday}
                    placeholder={__('Date of Birth')}
                    setText={__('Done')}
                    cancelText={__('Cancel')}
                    value={birthday ? birthday : null}
                    onSet={(e, a) => this.handleInput('birthday', a.element.value)}
                    dateFormat="dd/mm/yy"
                  />
                </IonItem>
                <Spacer size={1} />

                <IonItem lines="none" className="input-field-wrapper">
                  <IonInput
                    placeholder={__('Served by:')}
                    onIonChange={(e) => this.handleInput('served_by', e.target.value)}
                    required={true}
                    type="text"
                    pattern="text"
                    inputmode="text"
                    value={served_by}
                  ></IonInput>
                </IonItem>
                <Spacer size={1} />
              </IonList>
              <Spacer size={1} />

              <div className="flex-min">
                <IonButton
                  expand="block"
                  color="secondary"
                  onClick={() => {
                    const profile = {
                      first_name: first_name,
                      last_name: last_name,
                      mobile: mobile,
                      birthday: birthday,
                      served_by:served_by,
                      is_subscribed,
                    };
                    this.props.dispatch(updateProfile(profile));
                    forwardTo(getDefaultRoute().path);
                  }}
                >
                  {__('Continue')}
                </IonButton>
                <IonButton
                  expand="block"
                  fill="clear"
                  color="primary"
                  className="normal-text link  underlined transparent"
                  onClick={() => forwardTo(getDefaultRoute().path)}
                >
                  {__('Skip for now')}
                </IonButton>
              </div>
            </div>
          )}
        </Layout>
        <IonAlert
          isOpen={isRegisterModalOpen}
          onDidDismiss={() => this.props.dispatch(setModal('isRegisterModalOpen', false))}
          header={__('Success')}
          message={__('Register processed. Please check your mail')}
          buttons={[
            {
              text: __('Close'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.props.dispatch(setModal(('isRegisterModalOpen', false))),
            },
          ]}
        />
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth, isRegisterModalOpen, registerFormData, protectedReferrer } = state.profile;
  return {
    auth,
    isRegisterModalOpen,
    registerFormData,
    protectedReferrer,
  };
};

export default connect(stateToProps)(withTranslation(Register));
