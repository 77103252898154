import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NormalText, Spacer, StrongText, Title } from '../../components/common';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { withTranslation } from '../../lib/translate';
import NoData from '../../components/noData';
import { IonButton, IonCheckbox, IonLabel, IonItem } from '@ionic/react';
import { forwardTo } from '../../lib/utils';

import './index.css';

class TermsSubsciptionPackage extends Component {
  constructor(props) {
    super(props);
    this.state = { checked: false };
  }

  handleAcceptTerms = () => this.setState({ checked: !this.state.checked });

  handleContinue = () => {
    forwardTo('/checkout-membership');
  };

  render() {
    const { __, subscription } = this.props;
    const terms = subscription ? subscription.terms : null;
    return (
      <Loading>
        <Layout hideSecondToolbar color="transparent" headerTitle={__('Terms & Conditions')}>
          <div className="absolute-content flex-row-wrapper">
            <div className="scrollable-y">
              <Title>{__('Terms & Conditions')}</Title>
              <Spacer size={1} />
              {terms ? (
                <div className="terms-wrapper" dangerouslySetInnerHTML={{ __html: terms }} />
              ) : (
                <NoData />
              )}
            </div>
            <div className="flex-min">
              <IonItem lines="none">
                <IonCheckbox
                  color="secondary"
                  slot="start"
                  checked={this.state.checked}
                  onClick={this.handleAcceptTerms}
                />
                <NormalText color='primary' className="strong-text">{__('I accept the terms & conditions')}</NormalText>
              </IonItem>
              <IonButton
                color="secondary"
                disabled={!this.state.checked}
                onClick={this.handleContinue}
                expand="block"
                className={'checkout-btn'}
              >
                {__('Continue')}
              </IonButton>
            </div>
          </div>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  return {
    subscription: state.subscription.subscriptions[state.orders.selectedSubscription?.idx],
  };
};

export default connect(stateToProps)(withTranslation(TermsSubsciptionPackage));
