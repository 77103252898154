import React from 'react';
import {
  IonButton, IonItem, IonInput, /*, isPlatform */
  IonText
} from '@ionic/react';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import Clickup from '../../components/clickup';
import Modal from '../../components/modal';
import { Title, Spacer, NormalText, SmallText, FieldError, StrongText, Subtitle } from '../../components/common';
import { validateForm, isDefined } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { sendVoucherCode } from '../../store/actions';
import { forwardTo } from '../../lib/utils';
import QRCode from 'qrcode-react';
import moment from '../../lib/moment';
import './index.css';
import NoDataSecond from '../../components/noDataSecond';
import plateImage from '../../assets/images/icons/eat.png'


const VoucherItem = ({ reward, id, className, __, action }) => {
  return (
    <div
      style={{ backgroundImage: `url(${reward.image})` }}
      className={'voucher' + (className ? ' ' + className : '')}
      onClick={action ? () => action(id) : null}
    >
      {/* <div className='restaurant-logo-voucher'></div>
      <Subtitle className='bold lefted'>{__(reward.name)}</Subtitle> */}
    </div>
  );
};
class Vouchers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      voucherCode: '',
      selectedVoucher: null,
      formErrors: {
        voucherCode: '',
      },
    };
    this.formConfig = {
      voucherCode: { type: 'text', required: true },
    };
  }

  componentDidMount() {
    if (
      isDefined(this.props.location.state) &&
      isDefined(this.props.location.state.openVoucherModal)
    ) {
      setTimeout(() => {
        this.viewVoucher(this.props.location.state.openVoucherModal);
      }, 500);
    }
  }

  viewVoucher = (id) => {
    const { vouchers } = this.props;
    this.setState({ selectedVoucher: vouchers.find((v) => v.id === id) });
  };

  redeemVoucher = () => {
    const { voucherCode } = this.state;
    if (voucherCode !== '') {
      //console.log('Redeem voucher, code: "' + this.state.voucherCode + '"')
    }
  };

  clearFields = () => {
    this.setState({ voucherCode: '' });
  };

  sendCode = () => {
    const { dispatch, __ } = this.props;
    let formErrors = validateForm(this.formConfig, this.state, __);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { voucherCode } = this.state;
      const data = { code: voucherCode.toUpperCase() };
      this.clearFields();
      dispatch(sendVoucherCode(data));
    }
  };

  handlerOfVoucherCode = (e) => {
    const val = e.target.value.toUpperCase();
    this.setState({ voucherCode: val });
  };

  render() {
    const { __, vouchers, qr_code } = this.props;
    const { voucherCode, selectedVoucher } = this.state;
    let voucherRes = (vouchers || []).filter((data) => {
      if (data.type === 1) {
        return data;
      }
    });
    const clickupContent = (
      <Clickup label={__('Redeem Offer Code')} >
        <div className="absolute-wrapper flex-row-wrapper">
          <div className="padded no-padding-bottom centered scrollable-y " style={{ width: '100%' }}>
            <NormalText style={{ width: '70%', margin: 'auto' }} className="no-margin-top block">
              {__('Enter a valid voucher code below to receive your reward')}
            </NormalText>
            <Spacer size={1} />
            <div className="lefted no-padding-bottom voucher-item">

              <IonItem lines='none' className='input-field-wrapper'>
                <IonInput
                  placeholder={__('Voucher Code')}
                  onIonChange={(e) => this.handlerOfVoucherCode(e)}
                  value={voucherCode}
                ></IonInput>
                <FieldError className="field-error" value={__(this.state.formErrors.voucherCode)} />
              </IonItem>
            </div>
            <Spacer />
            <IonButton
              disabled={voucherCode === ''}
              color="secondary"
              expand="block"
              onClick={this.sendCode}
            >
              {__('Submit')}
            </IonButton>
          </div>

        </div>
      </Clickup>
    );
    return (
      <Loading transparent>
        <Layout headerWithTitle={true} color='transparent' hideSecondToolbar={true} headerTitle={__('Voucher Wallet')} scrollY={false}>
          <div className="absolute-content flex-row-wrapper">
            <div className="flex-min ion-text-center">
              <Title className='centered web-only'>{__('Voucher Wallet')}</Title>
              <Spacer size={1} />
            </div>
            <div className="scrollable-y">
              {!voucherRes || voucherRes.length === 0 ? (
                <NoDataSecond page='vouchers' label="Your vouchers will appear here" />
              ) : null}
              {voucherRes &&
                voucherRes.map((voucher, index) => {
                  return (
                    <VoucherItem
                      key={'vc-' + index}
                      {...voucher}
                      __={__}
                      action={this.viewVoucher}
                    />
                  );
                })}
              <Spacer size={2} />
            </div>
          </div>
          <div className="flex-min">{clickupContent}</div>

          {/* {!isPlatform('ios') ? clickupContent : null} */}
        </Layout>
        {/* {isPlatform('ios') ? clickupContent : null} */}
        <Modal
          className="voucher-modal"
          isOpen={selectedVoucher !== null}
          onDidDismiss={() => this.setState({ selectedVoucher: null })}
          // title={__('Voucher Info')}
        >
          {selectedVoucher && selectedVoucher.reward ? (
            <div style={{textAlign:'center'}}>
              <Title >{__('Voucher Info')}</Title>
              <VoucherItem
                {...selectedVoucher}
                __={__}
              />
              <div className="voucher-modal-content">
                {selectedVoucher.reward.name ? (
                  <>
                    <Title className="centered uppercase bold no-margin-bottom">{selectedVoucher.reward.name}</Title>
                  </>
                ) : null}
                {selectedVoucher.reward.small_print ? (
                  <div>
                    <NormalText>{selectedVoucher.reward.small_print}</NormalText>
                  </div>
                )
                  : null}
                <hr />
                {selectedVoucher.reward.expiry_date ? (

                  <div>

                    <StrongText>
                      {__('Expires:')}{' '}
                      {moment(selectedVoucher.reward.expiry_date, 'YYYY/MM/DD').format('DD/MM/YY')}
                    </StrongText>
                  </div>
                ) : null}
                <Spacer size={1} />
                <>
                  {isDefined(qr_code) ? (
                    <div className="qr-holder">
                      <QRCode value={qr_code} size={150} />
                    </div>
                  ) : (
                    <div className="noQrCode">
                      <h5>{__('NO QR CODE')}</h5>
                    </div>
                  )}
                </>
                {selectedVoucher.reward.description ? (
                  <div>
                    <SmallText className='block small-line-height'>{__(selectedVoucher.reward.description)}</SmallText>
                  </div>
                ) : null}

              </div>
            </div>
          ) : null}
        </Modal>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  return {
    vouchers: state.profile.vouchers || [],
    qr_code: state.profile.profile.qr_code,
  };
};

export default connect(stateToProps)(withTranslation(Vouchers));
